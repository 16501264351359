import { Box, Button, Flex, Group, Paper, Stack, Title } from "@mantine/core";
import { useMobile } from "shared/hooks/useMobile";
import { useWorkInProgress } from "shared/hooks/_service/useWorkInProgress";
import { Sizes } from "shared/types/mantine";
import { TableBody } from "shared/types/table";
import { RefreshButton } from "shared/ui/_buttons/RefreshButton";
import { Table } from "../Table";

type Props = {
  titles: string[];
  body: TableBody[] | null;
  title?: string;
  pending?: boolean;
  onRefresh?: () => void;
  size?: Sizes;
  buttonProps?: {
    title: string;
    disabled?: boolean;
    onClick?: (e: any) => void;
  };
  className?: string;
  height?: number | string;
  maxHeight?: number | string;
  afterHeightFrom?: Sizes;
  afterHeight?: number | string;
};

export const TablePaper = ({
  title,
  buttonProps,
  titles,
  onRefresh,
  pending,
  body,
  size,
  className,
  height,
  maxHeight = "auto",
  afterHeightFrom,
  afterHeight,
}: Props) => {
  const handleWorkInProgress = useWorkInProgress();
  const { setOption } = useMobile();

  const afterHeightProps = afterHeightFrom ? { [afterHeightFrom]: afterHeight } : {};

  const button = buttonProps && (
    <Button onClick={buttonProps.onClick || handleWorkInProgress} {...buttonProps} w={"inherit"}>
      {buttonProps?.title || "Перейти"}
    </Button>
  );

  return (
    <Box className={className} h={"100%"}>
      <Paper mah={maxHeight} h={{ base: height, ...afterHeightProps }}>
        <Stack h={"100%"}>
          <Flex justify={"space-between"} direction={{ base: "column", sm: "row" }} wrap={"wrap"}>
            <Group gap={"xs"}>
              <RefreshButton loading={pending} onClick={onRefresh} />
              <Title order={3}>{title || "Нет названия"}</Title>
            </Group>
            {button}
          </Flex>
          <Box
            style={{
              border: "1px solid rgba(47,47,47,1)",
              borderRadius: "10px",
              paddingTop: "5px",
            }}
            h="100%"
          >
            <Table titles={titles} pending={pending} body={body} h={"100%"} size={size} />
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
};
