import { ActionIcon, Button, Menu } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";
import { getLinkPath } from "shared/helpers/getLinkPath";
import {
  ButtonActionsType,
  ButtonNavbar,
  InvestmentItem,
  LinkNavbar,
  MenuNavbar,
} from "shared/types/navbar";
import styles from "./Mobile.module.scss";

export const navbarItems = {
  investment(item: InvestmentItem, buttonActions: ButtonActionsType, mainRoute: string) {
    return (
      <ActionIcon
        key={item.link}
        component={Link}
        to={item.link}
        size={"xl"}
        className={styles.mainLink}
        data-active={item.link === mainRoute || null}
      >
        <item.icon className={styles.linkIcon} stroke={1} />
      </ActionIcon>
    );
  },

  link(item: LinkNavbar, buttonActions: ButtonActionsType, mainRoute: string) {
    return (
      <ActionIcon
        key={item.link}
        component={Link}
        target={item.target}
        to={getLinkPath(item.link, item.param)}
        size={"xl"}
        className={item.main ? styles.mainLink : styles.link}
        data-active={item.link === mainRoute || null}
      >
        <item.icon className={styles.linkIcon} stroke={1} />
      </ActionIcon>
    );
  },
  button(item: ButtonNavbar, buttonActions: ButtonActionsType, mainRoute: string) {
    return (
      <Button
        key={item.action}
        size={"xl"}
        className={styles.link}
        onClick={buttonActions[item.action]}
      >
        <item.icon className={styles.linkIcon} stroke={1} />
      </Button>
    );
  },
  menu(item: MenuNavbar, children: React.JSX.Element[]) {
    return (
      <Menu offset={20} withArrow key={item.label}>
        <Menu.Target>
          <ActionIcon size={"xl"} className={styles.link}>
            <item.icon className={styles.linkIcon} stroke={1} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>{children}</Menu.Dropdown>
      </Menu>
    );
  },
};

export const menuItems: any = {
  link(item: LinkNavbar, buttonActions: ButtonActionsType, mainRoute: string) {
    return (
      <Menu.Item
        key={item.link}
        component={Link}
        target={item.target}
        to={getLinkPath(item.link, item.param)}
        leftSection={<item.icon className={styles.linkIcon} stroke={1} />}
        className={styles.link}
        h={"100%"}
        w={300}
        m={5}
        p={10}
        data-active={item.link === mainRoute || null}
      >
        {item.label}
      </Menu.Item>
    );
  },
  button(item: ButtonNavbar, buttonActions: ButtonActionsType, mainRoute: string) {
    return (
      <Menu.Item
        key={item.action}
        leftSection={<item.icon className={styles.linkIcon} stroke={1} />}
        className={styles.link}
        onClick={buttonActions[item.action]}
        h={"100%"}
        w={250}
        m={5}
        p={10}
      >
        {item.label}
      </Menu.Item>
    );
  },
};
