import { Anchor, Box, Container, Group, Title } from "@mantine/core";
import cx from "clsx";
import { Link } from "shared/ui/_links/Link";
import styles from "./index.module.scss";

const col6 = cx(styles.col6, styles.withoutMAouto, styles.withoutPb);

export const Footer = () => (
  <Box component={"footer"} className={styles.wrapper}>
    <Container h={"inherit"} size={"xxl"}>
      <Box className={styles.row}>
        <Group className={cx(col6, styles.footerLeftItems)}>
          <Anchor fw={700} c={"white"} to={"/faq"} component={Link}>
            FAQ
          </Anchor>
          <Anchor
            fw={700}
            c={"white"}
            to={"https://t.me/Platforma_Investra_Support_Bot"}
            target={"_blank"}
            component={Link}
          >
            Поддержка
          </Anchor>
          <Anchor
            fw={700}
            c={"white"}
            href={"https://xn--80adjkzugi.xn--p1ai/docs"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Документы
          </Anchor>
        </Group>
        <Box className={cx(col6, styles.footerRightItems)}>
          <Title ta={"right"} order={6} className={styles.footerCompanyName}>
            ООО "Инвестра"
          </Title>
          <Link target={"_blank"} to={"https://cbr.ru/rbr/rbr_fr/doc/?id=35990"} size={"xs"}>
            Платформа состоит в реестре инвестиционных платформ Банка России от 01.03.2023
          </Link>
        </Box>
      </Box>
    </Container>
  </Box>
);
