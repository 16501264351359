import { Box, Text, Title } from "@mantine/core";
import React from "react";

type Props = {
  title: string;
  description: string | React.ReactNode | React.ReactNode[];
};

export const TitleWithDescription = ({ title, description }: Props) => {
  return (
    <Box>
      <Title order={1}>{title}</Title>
      <Text c={"dimmed"}>{description}</Text>
    </Box>
  );
};
