/* eslint-disable no-console */
import "@mantine/carousel/styles.css";
import { Button, Container, MantineProvider, Title } from "@mantine/core";
import "@mantine/core/styles.css";
import { DatesProvider } from "@mantine/dates";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import { useMediaQuery } from "@mantine/hooks";
import { closeAllModals, ModalsProvider, openModal } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import "@mantine/tiptap/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorFallback } from "components/_error/ErrorFallback";
import "dayjs/locale/ru";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { RouterProvider } from "react-router-dom";
import { zodErrorMap } from "shared/helpers/validation/zodErrorMap";
import { variantColorResolver } from "shared/ui/variantColorResolver";
import { TitleWithDescription } from "shared/ui/_typography/titles/TitleWithDescription";
import { z } from "zod";
import { router } from "./router";
import { mobileTheme as mobileThemeMantine, resolver, theme } from "./shared/ui/theme";

const queryClient = new QueryClient();
z.setErrorMap(zodErrorMap);

function App() {
  const mobile = useMediaQuery("(max-width: 800px)");
  const mobileTheme = mobile ? mobileThemeMantine : {};

  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [notificationPermission, setNotificationPermission] = useState<NotificationPermission>(
    (localStorage.getItem("notificationPermission") as NotificationPermission) ||
      Notification.permission,
  );

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
  }, []);

  const openInstallPrompt = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the PWA install prompt");
        } else {
          console.log("User dismissed the PWA install prompt");
        }
        setDeferredPrompt(null);
        closeAllModals();
      });
    }
  };

  // useEffect(() => {
  //   const isPromptDismissed = localStorage.getItem("install_prompt_dismissed") === "true";

  //   if (deferredPrompt && !isPromptDismissed && mobile) {
  //     setTimeout(() => {
  //       openModal({
  //         title: (
  //           <Title order={3} ml={"1rem"}>
  //             Investra
  //           </Title>
  //         ),
  //         children: (
  //           <Container>
  //             <TitleWithDescription
  //               title={""}
  //               description={"Хотите установить это приложение на главный экран?"}
  //             />
  //             <Button mt={"1rem"} fullWidth variant="filled" onClick={openInstallPrompt}>
  //               Установить
  //             </Button>
  //           </Container>
  //         ),
  //         onClose: () => {
  //           if (deferredPrompt) {
  //             console.log("User dismissed the install prompt");
  //             localStorage.setItem("install_prompt_dismissed", "true");
  //             closeAllModals();
  //           }
  //         },
  //       });
  //     }, 15000);
  //   }
  // }, [deferredPrompt]);

  const requestNotificationPermission = () => {
    if ("Notification" in window) {
      Notification.requestPermission()
        .then((permission) => {
          setNotificationPermission(permission);
          localStorage.setItem("notificationPermission", permission);

          if (permission === "granted") {
            console.log("Notifications permission granted");
            closeAllModals();
          } else if (permission === "denied") {
            console.log("Notifications permission denied");
            closeAllModals();
          }
        })
        .catch((error) => {
          console.error("Error requesting notification permission:", error);
        });
    } else {
      console.error("Notifications API not supported on this device.");
    }
  };

  useEffect(() => {
    if (notificationPermission === "default" && mobile) {
      const notificationPromptTimeout = setTimeout(() => {
        openModal({
          title: (
            <Title order={3} ml={"1rem"}>
              Разрешение на уведомления
            </Title>
          ),
          children: (
            <Container>
              <TitleWithDescription
                title={""}
                description={"Хотите получать уведомления от этого приложения?"}
              />
              <Button
                mt={"1rem"}
                fullWidth
                variant="filled"
                onClick={requestNotificationPermission}
              >
                Разрешить уведомления
              </Button>
            </Container>
          ),
          onClose: () => {
            if (notificationPermission === "default") {
              setNotificationPermission("denied");
              localStorage.setItem("notificationPermission", "denied");
              closeAllModals();
            }
          },
        });
      }, 5000);

      return () => clearTimeout(notificationPromptTimeout);
    }
  }, [notificationPermission, mobile]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <MantineProvider
        defaultColorScheme={"dark"}
        theme={{ ...theme, variantColorResolver, primaryShade: 6, ...mobileTheme }}
        cssVariablesResolver={resolver}
      >
        <ModalsProvider>
          <Notifications />
          <QueryClientProvider client={queryClient}>
            <DatesProvider settings={{ locale: "ru" }}>
              <RouterProvider router={router} />
            </DatesProvider>
          </QueryClientProvider>
        </ModalsProvider>
      </MantineProvider>
    </ErrorBoundary>
  );
}

export default App;
