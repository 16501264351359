export const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/service-worker.js");

      return registration;
    } catch (error) {
      console.error("Service Worker registration failed:", error);
    }
  } else {
    console.error("Service Workers are not supported in this browser.");
  }
};

export const subscribeUserToPush = async (registration) => {
  const publicKey =
    "BMzYsREuowwsagFyE9aujreVSMWxS97ixij4muQRFkFVk9YuXEn3I_n6V65E4X9Il7qduEwRNnyHCGEDju0RqFY"; // VAPID ключ

  const permissionGranted = await requestNotificationPermission();
  if (!permissionGranted) return;

  try {
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicKey),
    });

    await fetch('https://31.129.56.44:8000/subscribe', {
      method: "POST",
      body: JSON.stringify(subscription),
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.error("Failed to subscribe the user:", error);
  }
};

const requestNotificationPermission = async () => {
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    return true;
  } else {
    console.error("Notification permission denied.");
    return false;
  }
};

const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
