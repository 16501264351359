import { useMediaQuery } from "@mantine/hooks";
import { BREAKPOINTS } from "shared/const/breakpoints";

export const useMobile = () => {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS["md"]})`);

  const isMobileUndefined = typeof isMobile !== "boolean";

  const setOption = <T, I>(mobileOption: T, desktopOption: I) => {
    if (isMobile) return mobileOption;
    else return desktopOption;
  };

  const setMobileOption = <T>(option: T) => {
    if (!isMobile) return;
    else return option;
  };

  const setDesktopOption = <T>(option: T) => {
    if (isMobile) return;
    else return option;
  };

  return { isMobile, setDesktopOption, setMobileOption, setOption, isMobileUndefined };
};
